<template>
  <div v-loading="formLoading">
    <div class="card mb-5 mb-xl-10" v-if="statusID">
      <div class="card-header border-0 pt-6">
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center mb-2 float-left">
            <span class="text-gray-900 fs-2 fw-bolder me-1">{{ formData.title }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header border-0 pt-6">
        <el-form
          :model="formData"
          :rules="rules"
          ref="formRef"
          class="w-100"
        >
          <div class="row g-9">
            <div class="col-md-6 fv-row">
              <div class="mb-5">
                <label class="required fs-6 fw-bold mb-2">{{ constants.orderProductStatus.STATUS_TITLE }}</label>
                <el-form-item prop="title">
                  <el-input
                    v-model="formData.title"
                    type="text"
                    placeholder="Title"
                  />
                </el-form-item>
              </div>
            </div>
          </div>
        <div class="row g-9">
          <div class="col-md-8 fv-row mb-5">
            <div class="mb-5">
              <label class="required fs-6 fw-bold mb-2">{{ constants.orderProductStatus.SET_AS }}</label>
              <br />
              <el-radio-group v-model="formData.statusType">
                <el-radio
                  v-for="(item, i) in formData.statusTypeOptions"
                  :key="'status' + i"
                  :label="item.value"
                  >{{ item.text }}</el-radio
                >
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="row g-9">
          <div class="col-md-6 fv-row mb-5">
            <div class="row">
              <div class="col-md-9 align-items-center">
                <div class="mb-5">
                  <label class="fs-6 fw-bold mb-2">{{ constants.orderProductStatus.BADGE }}</label>
                  <div v-if="formData.statusType == 'custom'">
                    <el-select size="large" v-model="formData.badge" class="w-100" placeholder="Select Badge" filterable>
                      <el-option
                        v-for="(item, i) in formData.badgeOptions"
                        :key="'badge' + i"
                        :label="item.title"
                        :value="item.name"
                      />
                    </el-select>
                  </div>
                  <br/>
                  <span class="badge badge-light-success" v-if="formData.statusType == 'completed'">badge-success</span>
                  <span class="badge badge-light-dark" v-if="formData.statusType == 'received'">badge-dark</span>
                  <span class="badge badge-light-warning" v-if="formData.statusType == 'in_progress'">badge-warning</span>
                  <span class="badge badge-light-danger" v-if="formData.statusType == 'cancelled'">badge-danger</span>
                  <span class="badge badge-light-info" v-if="formData.statusType == 'shipped'">badge-info</span>
                  <span class="badge badge-light-primary" v-if="formData.statusType == 'hold'">badge-primary</span>
                  <span class="badge badge-light-danger" v-if="formData.statusType == 'reject'">badge-danger</span>
                </div>
              </div>
              <div class="col-md-3 d-flex align-items-center justify-content-start position-relative">
                <i @mouseover="hover=true" @mouseleave="hover=false" class="bi bi-question-circle"></i>
                <div v-if="hover" class="rounded border p-2 pb-0" style="position: absolute;right:15px;background-color: aliceblue;top:0">
                  <h6 class="text-center">{{ constants.orderProductStatus.HELP }}</h6>
                  <div>
                    <span class="badge mb-2 badge-light-primary">badge-primary</span><br>
                    <span class="badge mb-2 mt-1 badge-light-success">badge-success</span><br>
                    <span class="badge mb-2 mt-1 badge-light-danger">badge-danger</span><br>
                    <span class="badge mb-2 mt-1 badge-light-warning">badge-warning</span><br>
                    <span class="badge mb-2 mt-1 badge-light-info">badge-info</span><br>
                    <span class="badge mb-2 mt-1 badge-light-dark">badge-dark</span><br>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 fv-row">
            <div class="row">
              <div class="col-md-4">
                <div class="mb-5">
                  <label class="fs-6 fw-bold mb-2">{{ constants.orderProductStatus.USE_ON_JOB_BOARD }}</label>
                  <el-form-item prop="job_board_visibility">
                    <el-switch
                      v-model="formData.job_board_visibility"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                    />
                  </el-form-item>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-5">
                  <label class="fs-6 fw-bold mb-2">{{ constants.orderProductStatus.INTERNAL_STATUS }}</label>
                  <el-form-item prop="internal_product_status">
                    <el-switch
                      v-model="formData.internal_product_status"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                    />
                  </el-form-item>
                </div>
              </div>
              <div class="col-md-4" v-if="formData.internal_product_status">
                <label class="required fs-6 fw-bold mb-2">{{ constants.orderProductStatus.CUSTOMER_STATUS }}</label>
                <el-form-item prop="customer_status">
                  <el-select v-model="formData.customer_status" size="large" placeholder="Select Customer Status">
                    <el-option
                      v-for="(item, i) in formData.orderProductStatusData"
                      :key="'customer_status' + i"
                      :label="item.title"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <div class="mb-5">
              <label class="fs-6 fw-bold mb-2">{{ constants.general.SORTORDER }}</label>
              <el-form-item prop="sort_order">
                <el-input-number
                  v-model="formData.sort_order"
                  controls-position="right"
                />
              </el-form-item>
            </div>
          </div>
          <div class="col-md-2">
            <div class="mb-5">
              <label class="fs-6 fw-bold mb-2">{{ constants.general.IS_DEFAULT }}</label>
              <el-form-item prop="is_default">
                <el-switch
                  @change="updateStatus($event)"
                  v-model="formData.is_default"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                />
              </el-form-item>
            </div>
          </div>
          <div class="col-md-2">
            <div class="mb-5">
              <label class="fs-6 fw-bold mb-2">{{ constants.general.STATUS }}</label>
              <el-form-item prop="status">
                <el-switch
                  :disabled="formData.is_default == true"
                  v-model="formData.status"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                />
              </el-form-item>
            </div>
          </div>
        </div>
          <div class="row g-9">
            <div class="col-md-12 mb-5 mt-12">
                <button type="button" class=" btn btn-primary me-2" v-if="((permission.isEditAllowed) || (permission.isAddAllowed && permission.isEditAllowed))" :disabled="loading" @click="submitForm(formRef, 'save')">{{constants.general.SAVE}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </button>
                <button type="button" class=" btn btn-primary me-2" :disabled="loadingback" @click="submitForm(formRef, 'save_back')">{{constants.general.SAVEANDBACK}}<span v-if="loadingback" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
                <button type="button" class=" btn btn-secondary me-2" @click.prevent="$router.push({ name: 'order-product-status'})">{{constants.general.CANCEL}}</button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, onUpdated } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRoute,useRouter } from 'vue-router'
import { notificationFire } from "@/composable/notification.js";
import globalConstant from "@/core/data/globalConstant.js";
import { formatText } from "@/core/helpers/common";
import useEventBus from '../../composable/useEventBus'
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
export default {
  name: "add-order-product-status",
  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const formLoading = ref(false);
    const formRef = ref(null);
    const loading = ref(false);
    const loadingback = ref(false);
    const statusID = ref(0);
    const hover = ref(false);
    const route = useRoute();
    const router = useRouter();
    const constants = globalConstant;
    const formData = reactive({
      statusTypeOptions: [
        { text: "Custom", value: "custom" },
        { text: "Received", value: "received" },
        { text: "In Progress", value: "in_progress" },
        { text: "Completed", value: "completed" },
        { text: "Cancelled", value: "cancelled" },
        { text: "Reject", value: "reject" },
        { text: "Hold", value: "hold" },
        { text: "Shipped", value: "shipped" },
      ],
      badgeOptions: [
        { name: "light", title: "None" },
        { name: "primary", title: "badge-primary" },
        { name: "success", title: "badge-success" },
        { name: "danger", title: "badge-danger" },
        { name: "warning", title: "badge-warning" },
        { name: "info", title: "badge-info" },
        { name: "dark", title: "badge-dark" },
      ],
      orderProductStatusData: [],
      title: "",
      statusType: "custom",
      badge: "light",
      job_board_visibility: false,
      internal_product_status: false,
      customer_status: 0,
      customerStatus1:{},
      is_default: false,
      sort_order: 0,
      status: true,
      defaultStatusVal: null
    });
    const permission = reactive({
      isAddAllowed: false,
      isEditAllowed: false,
    })
    let redirect = ref(false);
    //Get click event of toolbar buttons
    onEvent('actionName', (actionName) => {
      if(actionName == "save"){
        submitForm(formRef, 'save', true)
      }else if(actionName=="save_back"){
        submitForm(formRef, 'save_back',true)
      }else if(actionName == "cancel"){
        router.push({ name: 'order-product-status'});
      }
    })
    const rules = ref({
      title: [
        {
          required: true,
          message: "Status title is required",
          trigger: "change",
        },
      ],
      customer_status: [
        {
          required: true,
          message: "Customer status is required",
          trigger: "change",
        },
      ],
    });

    const updateStatus = (status) => {
      if(status == true){
        formData.status = true;
      }
    }
    
    const submitForm = async (formEl, btnName, isToolbarButtonsSubmitAction=false) => {
      formEl = (isToolbarButtonsSubmitAction) ? formEl.value : formEl;
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":true,"action":btnName}) : (btnName == "save")?(loading.value = true) : (loadingback.value = true);
          let requestUrl = statusID.value > 0 ? 'orderproductstatus/'+statusID.value : 'orderproductstatus';
          let badgeClass = null;

          if (formData.statusType == "completed") {
            badgeClass = "success";
          } else if (formData.statusType == "received") {
            badgeClass = "dark";
          } else if (formData.statusType == "in_progress") {
            badgeClass = "warning";
          } else if (formData.statusType == "cancelled") {
            badgeClass = "danger";
          } else if (formData.statusType == "shipped") {
            badgeClass = "info";
          } else if (formData.statusType == "hold") {
            badgeClass = "primary";
          } else if (formData.statusType == "reject") {
            badgeClass = "danger";
          } else {
            badgeClass = formData.badge;
          }
          ApiService.post(requestUrl, {
            _method: typeof route.params.id === "undefined" ? "POST" : "PUT",
            title: formData.title,
            type: formData.statusType,
            badge: badgeClass,
            job_board_visibility: formData.job_board_visibility == true ? 1 : 0,
            internal_product_status: formData.internal_product_status == true ? 1 : 0,
            customer_status: formData.customer_status,
            is_default: formData.is_default == true ? 1 : 0,
            sort_order: formData.sort_order,
            status: formData.status == true ? 1 : 0,
          })
          .then((response) => {
            if (response.data) {
              let status = response.status;
              let message = response.data.message;
              if (status == 200 || status == 201) {
                if (statusID.value == 0 || statusID.value === undefined) statusID.value = response.data.data.id;
                notificationFire(message,'success');
                if (btnName == 'save') {
                  (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":false,"action":btnName}) : (btnName == "save")?(loading.value = false) : (loadingback.value = false);
                  if (typeof route.params.id === "undefined") {
                    setCurrentPageBreadcrumbs(formatText('edit-order-product-status'), [{"name" :formatText('order-product-status'), 'path': 'order-product-status'}, {"name": formatText('edit-order-product-status') , "active": true}]);
                    router.push({ name: 'edit-order-product-status', params: { id: statusID.value } })
                  }
                } else if (btnName == 'save_back') {
                  router.push({ name: 'order-product-status' })
                }
              }
            }
          })
          .catch((error) => {
            let message = "";
            if (typeof error.response !== "undefined") {
              message = error.response.data.message;
            } else if (typeof error.message !== "undefined") {
              message = error.message;
            } else {
              message = "Something went wrong";
            }
            notificationFire(message, "error");
            (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":false,"action":btnName}) : (btnName == "save")?(loading.value = false) : (loadingback.value = false);
          });
        }
      })
    }
    
    //Get all order product status
    const getOrderProductStatusList = async () => {       
      formLoading.value=true;
      await ApiService.get("/orderproductstatus?fields=id,title&internal_product_status=0")
      .then(({ data }) => {
        if(data.data) {
          let productStatusData = data.data;
          productStatusData.splice(productStatusData.findIndex(item => item.id == route.params.id), 1)
          formData.orderProductStatusData = productStatusData;
          formData.customer_status = formData.orderProductStatusData[0]["id"];
        }
          formLoading.value = false;
      })
      .catch((error) => {
        formLoading.value = false;
        let message = "";
        if (typeof error.response !== "undefined") {
          message = error.response.data.message;
        } else if (typeof error.message !== "undefined") {
          message = error.message;
        } else {
          message = "Something went wrong";
        }
      });
    }

        //Get Order Product Status Data By Id
    const getOrderProductStatusById = async () => {
      if (route.params.id) {
        formLoading.value=true;
        await ApiService.query("/orderproductstatus/"+ statusID.value)
        .then(({ data }) => {
          if(data.status) {
            formData.title = data.data.title;
            formData.statusType = data.data.type;
            formData.badge = data.data.badge;
            formData.job_board_visibility = data.data.job_board_visibility == 1 ? true : 0;
            formData.internal_product_status = data.data.internal_product_status  == 1 ? true : 0;
            formData.defaultStatusVal = data.data.is_default;
            formData.is_default = data.data.is_default  == 1 ? true : 0;
            formData.status = data.data.status  == 1 ? true : 0;
            formData.sort_order = data.data.sort_order;
            if(data.data.internal_product_status==1 && data.data.customer_status!=''){
              formData.customer_status =data.data.customer_status;
            }
          }
          formLoading.value = false;
        })
        .catch((error) => {
        formLoading.value = false;
        let message = "";
        let status = "";
        if (typeof error.response !== "undefined") {
          message = error.response.data.message;
          status = error.response.status;
        } else if (typeof error.message !== "undefined") {
          message = error.message;
        } else {
          message = "Something went wrong";
        }
        notificationFire(message, "error");
        if (status == 404) {
          router.push({ name: "orderproductstatus" });
        }
      });
      }
    }

    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(){
      return setCurrentPageActionButtons([{"name" :constants.general.SAVE, 'action': 'save','class': 'btn-primary me-3','isLoader': false,'isAllowed': (permission.isEditAllowed) || (permission.isAddAllowed && permission.isEditAllowed) }, {"name": constants.general.SAVEANDBACK , 'action': 'save_back','class': 'btn-primary me-3','isLoader':false }, {"name": constants.general.CANCEL , 'action': 'cancel','class': 'btn-secondary','isLoader':false }]);
    }
    //Set breadcrumbs
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name" :formatText('order-product-status'), 'path': 'order-product-status'}, {"name": formatText(route.name) , "active": true}]);
      let role = loggedInUserRoles()
      let per = loggedInUserPermissions();
      if (per.includes("createOrderProductStatus") || role == "Super Admin")
        permission.isAddAllowed = true;
      if (per.includes("changeOrderProductStatus") || role == "Super Admin")
        permission.isEditAllowed = true;

      if (route.params.id != "undefined")
      { 
        statusID.value =  route.params.id;
        getOrderProductStatusList();
      }
      
      if (statusID.value > 0) {
        await getOrderProductStatusById();
      }
      generateHeaderToolbarButtons();
    })
    onUpdated(async () => {
      generateHeaderToolbarButtons();
    })
    return {
      formData,
      rules,
      submitForm,
      formRef,
      loading,
      loadingback,
      redirect,
      constants,
      formLoading,
      permission,
      statusID,
      hover,
      formLoading,
      updateStatus
    };
  },
};
</script>